const IconHome = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.87435 15.375V10.1333C6.87435 9.73663 6.87435 9.53828 6.95156 9.38676C7.01947 9.25348 7.12783 9.14511 7.26111 9.0772C7.41263 9 7.61098 9 8.00769 9H9.99102C10.3877 9 10.5861 9 10.7376 9.0772C10.8709 9.14511 10.9792 9.25348 11.0471 9.38676C11.1244 9.53828 11.1244 9.73663 11.1244 10.1333V15.375M1.91602 7.22917L8.31935 2.42667C8.5632 2.24378 8.68513 2.15234 8.81903 2.11709C8.93723 2.08597 9.06147 2.08597 9.17967 2.11709C9.31358 2.15234 9.4355 2.24378 9.67935 2.42667L16.0827 7.22917M3.33269 6.16667V13.1083C3.33269 13.9017 3.33269 14.2984 3.48709 14.6015C3.62291 14.868 3.83964 15.0848 4.1062 15.2206C4.40924 15.375 4.80594 15.375 5.59935 15.375H12.3994C13.1928 15.375 13.5895 15.375 13.8925 15.2206C14.1591 15.0848 14.3758 14.868 14.5116 14.6015C14.666 14.2984 14.666 13.9017 14.666 13.1083V6.16667L10.3594 2.93667C9.87165 2.57089 9.6278 2.388 9.35999 2.31751C9.12359 2.25528 8.87511 2.25528 8.63871 2.31751C8.3709 2.388 8.12705 2.57089 7.63935 2.93667L3.33269 6.16667Z"
        stroke="#3679FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconHome;
