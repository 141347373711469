import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useServices } from "../servicesContext";
import {
  debounce,
  extractIdFromPath,
  findObjectById,
  getBoundingByReactRef,
  getLoginUrlByServiceName,
} from "../helpers/helpers";
import { eApi } from "../helpers/eApi";
import IconArrowLeft from "../components/IconArrowLeft";
import IconArrowRight from "../components/IconArrowRight";
import IconHome from "../components/IconHome";
import IconReload from "../components/IconReload";
import IconTrash from "../components/IconTrash";
import IconMail from "../components/IconMain";

const AddServicePage = () => {
  const browserViewContainerRef = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { installedServices, setInstalledServices } = useServices();
  const [serviceInfo, addServiceInfo] = useState(null);

  function setResizeListener(browserViewContainerRef, delay = 300) {
    const resizeHandler = debounce(() => {
      eApi.resize(getBoundingByReactRef(browserViewContainerRef));
    }, delay);

    window.addEventListener("resize", resizeHandler);
    return resizeHandler;
  }

  const rename = (event) => {
    const serviceId = extractIdFromPath(pathname);

    function addCustomNameById(data, id, customName) {
      return data.map((group) =>
        group.map((item) => {
          if (item.id === id) {
            return { ...item, customName };
          }
          return item;
        })
      );
    }

    const updatedServices = addCustomNameById(
      installedServices,
      serviceId,
      event.target.value
    );

    setInstalledServices(updatedServices);
  };

  const handleRemove = () => {
    const serviceId = extractIdFromPath(pathname);

    // Find and filter out the service to remove
    const updatedServices = installedServices
      .map((group) => group.filter((service) => service.id !== serviceId))
      .filter((group) => group.length > 0); // Ensure empty groups are removed

    // Update the state
    setInstalledServices(updatedServices);

    // Navigate back to the services list after removal
    eApi.remove(pathname);
    navigate("/services");
  };

  useEffect(() => {
    // Resize
    const resizeHandler = setResizeListener(browserViewContainerRef);

    var service = findObjectById(
      installedServices,
      extractIdFromPath(pathname)
    );

    if (service && service.name) {
      addServiceInfo({ ...service, customName: service?.customName });

      localStorage.setItem("activePath", pathname);
    } else {
      navigate("/services");
    }

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [pathname, browserViewContainerRef, installedServices, navigate]);

  useEffect(() => {
    var service = findObjectById(
      installedServices,
      extractIdFromPath(pathname)
    );

    eApi.navigate(
      getLoginUrlByServiceName(service.name),
      pathname,
      getBoundingByReactRef(browserViewContainerRef)
    );
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <div className="h-100 d-flex flex-column">
      <div
        className="flex align-items-center"
        style={{ height: "40px", padding: "0 12px" }}
      >
        <div className="d-flex align-items-center me-4 d-none">
          <img
            className="me-1"
            alt={serviceInfo?.name}
            src={serviceInfo?.iconUrl}
            style={{ width: "40px", height: "40px" }}
          />
          <div className="fw-bold">{serviceInfo?.name}</div>
        </div>

        <button
          className="navigation-button"
          title="Navigate back"
          onClick={() => eApi.goBack(pathname)}
        >
          <IconArrowLeft />
        </button>

        <button
          className="home-button"
          title="Navigate to homepage"
          onClick={() =>
            eApi.goHome(getLoginUrlByServiceName(serviceInfo.name), pathname)
          }
        >
          <IconHome />
          Home
        </button>

        <button
          className="navigation-button"
          title="Navigate forward"
          onClick={() => eApi.goForward(pathname)}
        >
          <IconArrowRight />
        </button>
        <button className="normal-button" onClick={() => eApi.reload(pathname)}>
          <IconReload />
        </button>

        {serviceInfo?.name !== undefined && (
          <div className="ms-3" style={{ position: "relative" }}>
            <div style={{ position: "absolute", left: "6px", top: "0" }}>
              <IconMail />
            </div>
            <input
              type="text"
              className="edit-input"
              value={serviceInfo.customName ? serviceInfo.customName : ""}
              onChange={rename}
              placeholder={
                serviceInfo?.customName
                  ? serviceInfo.customName
                  : serviceInfo.name
              }
            />
          </div>
        )}

        <button
          className="navigation-button"
          title="Remove account"
          style={{ marginLeft: "auto" }}
          onClick={handleRemove}
        >
          <IconTrash />
        </button>
      </div>

      <div
        ref={browserViewContainerRef}
        className="right browserview-container"
      ></div>
    </div>
  );
};

export default AddServicePage;
