const IconTrash = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 4.75033V4.18366C11.3333 3.39025 11.3333 2.99355 11.1789 2.69051C11.0431 2.42394 10.8264 2.20722 10.5598 2.0714C10.2568 1.91699 9.86007 1.91699 9.06667 1.91699H7.93333C7.13993 1.91699 6.74322 1.91699 6.44018 2.0714C6.17362 2.20722 5.95689 2.42394 5.82107 2.69051C5.66667 2.99355 5.66667 3.39025 5.66667 4.18366V4.75033M7.08333 8.64616V12.1878M9.91667 8.64616V12.1878M2.125 4.75033H14.875M13.4583 4.75033V12.6837C13.4583 13.8738 13.4583 14.4688 13.2267 14.9234C13.023 15.3232 12.6979 15.6483 12.2981 15.852C11.8435 16.0837 11.2484 16.0837 10.0583 16.0837H6.94167C5.75156 16.0837 5.1565 16.0837 4.70194 15.852C4.30209 15.6483 3.97701 15.3232 3.77328 14.9234C3.54167 14.4688 3.54167 13.8738 3.54167 12.6837V4.75033"
        stroke="#F4006D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTrash;
