import React from "react";
import { eApi } from "../helpers/eApi";
import { stripHtmlTags } from "../helpers/helpers";

function TitleBar({ name, id }) {
  document.title = stripHtmlTags(name);

  return (
    <div className="title-bar">
      <div className="title-bar__drag">
        <img
          className="title-bar__logo"
          src={id === "hub-mail" ? `/${id}-logo.svg` : `/${id}-logo.png`}
          alt={`${stripHtmlTags(name)} Logo`}
        />
        <span dangerouslySetInnerHTML={{ __html: name }}></span>
      </div>
      <div className="title-bar__controls">
      <div
          className="title-bar__minimize title-bar__control d-none"
          onClick={() => eApi.sticky()}
        ></div>        
        <div
          className="title-bar__minimize title-bar__control"
          onClick={() => eApi.minimize()}
        ></div>
        <div
          className="title-bar__maximize title-bar__control"
          onClick={() => eApi.maximize()}
        ></div>
        <div
          className="title-bar__close title-bar__control"
          onClick={() => eApi.close()}
        ></div>
      </div>
    </div>
  );
}

export default TitleBar;
