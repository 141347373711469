import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useServices } from "../servicesContext";

function Home() {
  const { installedServices } = useServices();
  const navigate = useNavigate();

  useEffect(() => {
    if (installedServices.length === 0) {
      navigate("/services");
    } else {
      const activePath = localStorage.getItem("activePath");
      navigate(
        activePath === "services"
          ? "/services"
          : activePath
      );
    }
  }, [navigate, installedServices.length]);

  return <div></div>;
}

export default Home;
