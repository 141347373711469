import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import ServiceDetail from "./pages/ServiceDetail";
import Services from "./pages/Services";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Review from "./pages/Review";
import InstalledServicesList from "./components/InstalledServices";
import IconStar from "./components/IconStar";
import IconPlus from "./components/IconPlus";
import { ServicesProvider } from "./servicesContext";
import TitleBar from "./components/TitleBar";
import { getAppInfoById } from "./helpers/helpers";

function App({ appId }) {
  const appInfo = getAppInfoById(appId);
  const isReviewed = localStorage.getItem("reviewed") === "true";

  return (
    <div data-app={appId} className={isReviewed ? "reviewed" : ""}>
      <TitleBar {...appInfo} />
      <div>
        <div className="d-flex app-height w-100 flex-column overflow-hidden">
          <div className="d-flex h-100">
            <ServicesProvider>
              <Router>
                <div className="c-sidebar d-flex flex-column align-items-center h-100">
                  <div className="hide-scrollbar w-100">
                    <InstalledServicesList />
                    <div className="d-flex justify-content-center">
                      <Link
                        className="add-services"
                        to="/services"
                        style={{ marginBottom: "20px" }}
                      >
                        <IconPlus />
                      </Link>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-center shadow js-review-menu-item"
                    style={{
                      marginTop: "auto",
                      position: "relative",
                      height: "60px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="icon-16x16 "
                      style={{
                        position: "relative",
                        top: "-2px",
                      }}
                    >
                      <div>
                        {appInfo && appInfo.msReviewId && (
                          <Link
                            className="rate-us"
                            title="Rate Us"
                            to="/review"
                          >
                            <IconStar />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex h-100 vw-100 overflow-auto">
                  <div className="right-container">
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route
                        path="/services"
                        element={<Services {...appInfo} />}
                      />
                      <Route
                        path="/services/:serviceId"
                        element={<ServiceDetail />}
                      />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy {...appInfo} />}
                      />
                      <Route path="/review" element={<Review {...appInfo} />} />
                    </Routes>
                  </div>
                </div>
              </Router>
            </ServicesProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
