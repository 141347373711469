const electron = window.electron;

/**
 * Wraps a function with logging.
 *
 * @param {string} fnName - The name of the function to log.
 * @param {Function} fn - The function to wrap.
 * @returns {Function} The wrapped function with logging.
 */
function logWrapper(fnName, fn) {
  return (...args) => {
    console.log(
      `%c💥 eApi%c ${fnName} %o`,
      "background: orange; color: black; padding: 2px 4px 2px 2px; font-weight: bold;",
      "background: black; color: white; padding: 2px 4px; font-weight: bold;",
      args
    );
    return fn(...args);
  };
}

/**
 * Wraps a function with error handling.
 *
 * @param {string} fnName - The name of the function to wrap.
 * @param {Function} fn - The function to wrap.
 * @returns {Function} The wrapped function with error handling.
 */
function errorWrapper(fnName, fn) {
  return (...args) => {
    if (electron) {
      try {
        return fn(...args);
      } catch (error) {
        console.error(`Error in function ${fnName}:`, error);
        throw error;
      }
    }
  };
}

// Original API functions
const eApiMethods = {
  /**
   * Sends a goBack message to the electron API.
   *
   * @param {string} pathname - The path to navigate back to.
   */
  goBack: (pathname) => {
    electron.sendMessage("eApi", [`goBack`, { id: pathname }]);
  },
  
  /**
   * Sends a goHome message to the electron API.
   *
   * @param {string} url - The URL to navigate to.
   * @param {string} pathname - The path to use for navigation.
   */
  goHome: (url, pathname) => {
    electron.sendMessage("eApi", [`goHome`, { id: pathname, url }]);
  },
  
  /**
   * Sends a goForward message to the electron API.
   *
   * @param {string} pathname - The path to navigate forward to.
   */
  goForward: (pathname) => {
    electron.sendMessage("eApi", [`goForward`, { id: pathname }]);
  },
  
  /**
   * Sends a reload message to the electron API.
   *
   * @param {string} pathname - The path to reload.
   */
  reload: (pathname) => {
    electron.sendMessage("eApi", [`reload`, { id: pathname }]);
  },
  
  /**
   * Sends a navigate message to the electron API.
   *
   * @param {string} url - The URL to navigate to.
   * @param {string} pathname - The path to use for navigation.
   * @param {Object} bounds - The bounds for the navigation window.
   */
  navigate: (url, pathname, bounds) => {
    eApiMethods.hide();
    electron.sendMessage("eApi", [`navigate`, { url, id: pathname, bounds }]);
  },
  
  /**
   * Sends a hide message to the electron API.
   */
  hide: () => {
    electron.sendMessage("eApi", [`hide`, {}]);
  },
  
  /**
   * Sends a remove message to the electron API.
   *
   * @param {string} pathname - The path to remove.
   */
  remove: (pathname) => {
    electron.sendMessage("eApi", [`remove`, { id: pathname }]);
  },
  
  /**
   * Sends a resize message to the electron API.
   *
   * @param {Object} bounds - The bounds for resizing.
   */
  resize: (bounds) => {
    electron.sendMessage("eApi", [`resize`, { bounds }]);
  },
  
  /**
   * Sends a maximize message to the electron API.
   */
  maximize: () => {
    electron.sendMessage("eApi", [`maximize`, {}]);
  },
  
  /**
   * Sends a minimize message to the electron API.
   */
  minimize: () => {
    electron.sendMessage("eApi", [`minimize`, {}]);
  },

  /**
   * Sends a sticky app message to the electron API.
   */
  sticky: () => {
    electron.sendMessage("eApi", [`sticky`, {}]);
  },  
  
  /**
   * Sends a close message to the electron API.
   */
  close: () => {
    electron.sendMessage("eApi", [`close`, {}]);
  },
};

export const eApi = {};
for (const [key, fn] of Object.entries(eApiMethods)) {
  const wrappedWithError = errorWrapper(key, fn); // Add error handling
  eApi[key] = logWrapper(key, wrappedWithError); // Add logging
}
