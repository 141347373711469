import React, { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useServices } from "../servicesContext";
import { getServiceIcon as getServiceIconUrl } from "../helpers/helpers";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";

const InstalledServicesList = () => {
  const { installedServices, setInstalledServices } = useServices();
  const navigate = useNavigate();
  const location = useLocation();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const draggingRef = useRef(false);

  const handleDragStart = () => {
    draggingRef.current = true;
  };

  const handleDragEnd = (event) => {
    draggingRef.current = false;
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = installedServices.findIndex(
        (item) => item[0].id === active.id
      );
      const newIndex = installedServices.findIndex(
        (item) => item[0].id === over.id
      );

      const newItems = arrayMove(installedServices, oldIndex, newIndex);
      setInstalledServices(newItems); // update the services context with the new order
    }
  };

  const handleClick = (id) => {
    if (!draggingRef.current) {
      navigate(`/services/${id}`);
    }
  };

  const isActive = (service, pathname) => {
    return pathname.includes(service.id);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={installedServices.map((item) => item[0].id)}
        strategy={verticalListSortingStrategy}
      >
        <div className="installedServices" style={{ paddingTop: "12px" }}>
          {installedServices.map((service) => (
            <SortableItem
              key={service[0].id}
              id={service[0].id}
              onClick={() => handleClick(service[0].id)}
            >
              <div className="d-flex justify-content-center mb-2">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <div
                    className={`menu-item__icon-container ${
                      isActive(service[0], location.pathname)
                        ? "menu-item__icon-container--active"
                        : ""
                    }`}
                  >
                    <img
                      src={getServiceIconUrl(service[0].name)}
                      alt={service[0].name}
                      style={{ width: "65%" }}
                    />
                  </div>
                  <span
                    className={`menu-item__name ${
                      isActive(service[0], location.pathname)
                        ? "menu-item__name--active"
                        : ""
                    }`}
                  >
                    {service[0].customName
                      ? service[0].customName
                      : service[0].name}
                  </span>
                </div>
              </div>
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default InstalledServicesList;
