import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useServices } from "../servicesContext";
import { generateUniqueId, getServiceIcon } from "../helpers/helpers";
import { eApi } from "../helpers/eApi";

const ItemList = ({ msReviewId, id, name }) => {
  const [data, setData] = useState([]); // Initialize empty items state
  const { setInstalledServices } = useServices();
  const navigate = useNavigate();

  useEffect(() => {
    eApi.hide();
    localStorage.setItem("activePath", "services");

    // Dynamically fetch items based on appId
    const fetchItems = async () => {
      try {
        const response = await fetch(`/${id}.json`); // Assuming a JSON endpoint

        if (!response.ok) {
          throw new Error(`Error fetching items: ${response.statusText}`);
        }
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error("Error fetching items:", error);
        // Handle errors appropriately, e.g., display an error message to the user
      }
    };

    fetchItems();

    return () => {};
  }, [id]); // Re-fetch items when appId changes

  const getCopyByContext = (index) => {
    return id === 'multi-mail' && index > 0 ? 'Add with Mailhub' : 'Add';
  }

  const handleItemClick = ({ name }, index) => {
    if (id === 'multi-mail' && index > 0) {
      window.location.href = `ms-windows-store://pdp/?ProductId=9N2X3BPM4X3S`;
      return;
    }
    const pathId = generateUniqueId();

    const newService = [
      {
        name,
        id: pathId,
      },
    ];
    setInstalledServices((prevServices) => [...prevServices, newService]);
    navigate(`/services/${pathId}`);
  };

  return (
    <div className="right" style={{ background: "white" }}>
      <main>
        <section className="pt-lg-8 pt-xl-7 pb-5">
          <div className="container pt-4 pt-lg-0">
            <div className="inner-container text-center mb-4 mb-lg-4">
              <h1 className="mb-4">{data.headline}</h1>
              <p>{data.subheadline}</p>
            </div>
            {(data.icons && data.icons.length === 2) && (
            <div className="d-flex justify-content-center align-items-center gap-2 gap-sm-4">
              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0">
                <img src={data.icons && getServiceIcon(data.icons[0])} className="w-50px" alt="" />
              </div>

              <div className="icon-xxl text-center shadow-sm bg-white rounded-circle flex-shrink-0 btn-ripple">
                <img src={id === 'hub-mail' ? `/${id}-logo.svg` : `/${id}-logo.png`} className="w-90px" alt="" />
              </div>

              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0">
                <img src={data.icons && getServiceIcon(data.icons[1])} className="w-50px" alt="" />
              </div>
            </div>
            )}

            {data.icons && data.icons.length === 6 && (
            <div className="d-flex justify-content-center align-items-center gap-2 gap-sm-4">
              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0 d-none d-md-block">
                <img src={data.icons && getServiceIcon(data.icons[0])} className="w-50px" alt="" />
              </div>

              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0 d-none d-sm-block">
                <img src={data.icons && getServiceIcon(data.icons[1])} className="w-50px" alt="" />
              </div>

              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0">
                <img src={data.icons && getServiceIcon(data.icons[2])} className="w-50px" alt="" />
              </div>

              <div className="icon-xxl text-center shadow-sm bg-white rounded-circle flex-shrink-0 btn-ripple">
                <img src={id === 'hub-mail' ? `/${id}-logo.svg` : `/${id}-logo.png`} className="w-90px" alt="" />
              </div>

              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0">
                <img src={data.icons && getServiceIcon(data.icons[3])} className="w-50px" alt="" />
              </div>

              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0 d-none d-sm-block">
                <img src={data.icons && getServiceIcon(data.icons[4])} className="w-50px" alt="" />
              </div>

              <div className="icon-xl text-center shadow-sm bg-white rounded-circle flex-shrink-0 d-none d-md-block">
                <img src={data.icons && getServiceIcon(data.icons[5])} className="w-50px" alt="" />
              </div>
            </div>
            )}
          </div>
        </section>
      </main>

      <section className="pt-0">
        <div className="container">
          <div className="row g-4 g-lg-5">
            {data.services &&
              data.services.map((service, index) => (
                <div
                  className="col-sm-6 col-lg-4 col-xl-3"
                  key={service.name}
                  onClick={() => handleItemClick(service, index)}
                >
                  <div className="card card-hover-shadow border-primary-hover border h-100 p-4">
                    <div className="card-body p-0">
                      <div className="d-flex gap-3 align-items-center mb-3">
                        <img
                          src={getServiceIcon(service.name)}
                          className="h-40px"
                          alt="Integrations-icon"
                        />
                        <h6 className="mb-0">{service.name}</h6>
                      </div>
                      <p>{service.desc}</p>
                    </div>

                    <div className="card-footer bg-transparent p-0">
                      <span className="icon-link icon-link-hover heading-color text-primary-hover stretched-link mb-0">
                        {getCopyByContext(index)}<i className="bi bi-arrow-right"></i>{" "}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <div className="row g-4 g-lg-5 mt-2">
            <Link to="/privacy-policy?app=true">Privacy Policy</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ItemList;
