const IconReload = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.91602 10.4167C1.91602 10.4167 2.00195 11.0182 4.49154 13.5078C6.98114 15.9974 11.0176 15.9974 13.5072 13.5078C14.3892 12.6257 14.9588 11.5495 15.2158 10.4167M1.91602 10.4167V14.6667M1.91602 10.4167H6.16602M16.0827 7.58333C16.0827 7.58333 15.9967 6.98179 13.5072 4.49219C11.0176 2.0026 6.98114 2.0026 4.49154 4.49219C3.60947 5.37426 3.03992 6.45051 2.78289 7.58333M16.0827 7.58333V3.33333M16.0827 7.58333H11.8327"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconReload;
