import React, { useState } from "react";
import { stripHtmlTags } from "../helpers/helpers";

function Review({ name, msReviewId }) {
  document.title = stripHtmlTags(name);
  const [isReviewed, setReview] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getRating = (event) => {
    if (parseInt(event.target.dataset.rating, 10) > 3) {
      window.location.href = `ms-windows-store://review/?ProductId=${msReviewId}`;
    }
    setLoading(true);
    setTimeout(() => {
      setReview(true);
    }, 1500);

    localStorage.setItem("reviewed", "true");
    document.querySelector("#root > div").classList.add("reviewed");
  };

  return (
    <section className="pt-xl-8">
      <div className="container">
        <div className="row g-4 g-xxl-5">
          <div className="col-xl-9 mx-auto">
            <div className="row mt-n5 mt-sm-n7 mt-md-n8">
              <div className="col-11 col-lg-9 mx-auto">
                <div className="card shadow p-4 p-sm-5 p-md-6 mt-5">
                  {isLoading && (
                    <div className="card-thank-you">
                      <p>Loading...</p>
                    </div>
                  )}
                  {isReviewed && (
                    <div className="card-thank-you">
                      <h1 className="h4">Thank you for your rating!</h1>
                    </div>
                  )}
                  <div className="card-header px-0 pt-0 pb-2">
                    <h1 className="mb-3 h3 text-center">
                      Enjoying{" "}
                      <span dangerouslySetInnerHTML={{ __html: name }}></span>?
                    </h1>
                    <p className="mb-0 text-center">
                      Thank you for installing our app. <br/>We'd really appreaciate
                      if you can leave us rating.
                    </p>
                    <div className="Stars">
                      <svg
                        className="Star"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18.5 17.6"
                        data-rating="1"
                        onClick={getRating}
                      >
                        <path
                          d="M9.3 0l2.8 5.8 6.4.9-4.6 4.6 1.1 6.3-5.7-3-5.8 3 1.1-6.3L0 6.7l6.4-.9z"
                          fill="inherit"
                        ></path>
                      </svg>
                      <svg
                        className="Star"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18.5 17.6"
                        data-rating="2"
                        onClick={getRating}
                      >
                        <path
                          d="M9.3 0l2.8 5.8 6.4.9-4.6 4.6 1.1 6.3-5.7-3-5.8 3 1.1-6.3L0 6.7l6.4-.9z"
                          fill="inherit"
                        ></path>
                      </svg>
                      <svg
                        className="Star"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18.5 17.6"
                        data-rating="3"
                        onClick={getRating}
                      >
                        <path
                          d="M9.3 0l2.8 5.8 6.4.9-4.6 4.6 1.1 6.3-5.7-3-5.8 3 1.1-6.3L0 6.7l6.4-.9z"
                          fill="inherit"
                        ></path>
                      </svg>
                      <svg
                        className="Star"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18.5 17.6"
                        data-rating="4"
                        onClick={getRating}
                      >
                        <path
                          d="M9.3 0l2.8 5.8 6.4.9-4.6 4.6 1.1 6.3-5.7-3-5.8 3 1.1-6.3L0 6.7l6.4-.9z"
                          fill="inherit"
                        ></path>
                      </svg>
                      <svg
                        className="Star"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18.5 17.6"
                        data-rating="5"
                        onClick={getRating}
                      >
                        <path
                          d="M9.3 0l2.8 5.8 6.4.9-4.6 4.6 1.1 6.3-5.7-3-5.8 3 1.1-6.3L0 6.7l6.4-.9z"
                          fill="inherit"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </section>
  );
}

export default Review;
