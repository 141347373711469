import React, { createContext, useState, useEffect, useContext } from "react";

// Create the context
const ServicesContext = createContext();

// Define the provider component
export const ServicesProvider = ({ children }) => {
  // Load data from localStorage or initialize with default data
  const initialData =
    JSON.parse(localStorage.getItem("installedServices")) || [];

  // Create state variable for services
  const [installedServices, setInstalledServices] = useState(initialData);

  // Update localStorage whenever installedServices state changes
  useEffect(() => {
    localStorage.setItem(
      "installedServices",
      JSON.stringify(installedServices)
    );
  }, [installedServices]);

  // Provide the context value
  const value = {
    installedServices,
    setInstalledServices,
  };

  // Return the provider with children
  return (
    <ServicesContext.Provider value={value}>
      {children}
    </ServicesContext.Provider>
  );
};

// Custom hook to use the context
export const useServices = () => {
  return useContext(ServicesContext);
};
