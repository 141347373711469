const IconMail = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.91602 5.45801L7.6995 9.50645C8.16783 9.83428 8.40199 9.99819 8.6567 10.0617C8.88169 10.1178 9.11701 10.1178 9.342 10.0617C9.5967 9.99819 9.83087 9.83428 10.2992 9.50645L16.0827 5.45801M5.31602 14.6663H12.6827C13.8728 14.6663 14.4678 14.6663 14.9224 14.4347C15.3223 14.231 15.6473 13.9059 15.8511 13.5061C16.0827 13.0515 16.0827 12.4565 16.0827 11.2663V6.73301C16.0827 5.5429 16.0827 4.94784 15.8511 4.49328C15.6473 4.09343 15.3223 3.76835 14.9224 3.56462C14.4678 3.33301 13.8728 3.33301 12.6827 3.33301H5.31602C4.1259 3.33301 3.53085 3.33301 3.07629 3.56462C2.67644 3.76835 2.35136 4.09343 2.14763 4.49328C1.91602 4.94784 1.91602 5.5429 1.91602 6.73301V11.2663C1.91602 12.4565 1.91602 13.0515 2.14763 13.5061C2.35136 13.9059 2.67644 14.231 3.07629 14.4347C3.53085 14.6663 4.1259 14.6663 5.31602 14.6663Z"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMail;
