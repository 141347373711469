import React from "react";
import { stripHtmlTags } from "../helpers/helpers";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = (appInfo) => {
  const location = useLocation();
  const isApp = location.search.includes("app=true");
  if (!isApp) {
    document.body.classList.add("remove-app-frame");
  }

  return (
    <div>
      <section className="pt-lg-5 pt-xl-6">
        <div className="container pt-4 pt-lg-0">
          <div className="row">
            <div className="col-xl-8 mx-auto">
              <div className="text-center mb-6">
                <h1>Privacy Policy - {stripHtmlTags(appInfo.name)}</h1>
                <p className="lead mb-0">Last update on Jun 2024</p>
              </div>

              <h6 className="mb-3">Introduction</h6>
              <p>
                Your privacy is important to us, and we are committed to
                safeguarding and respecting it. This Privacy Policy aims to
                inform you about the data we collect, how we use it, and your
                rights regarding that data.
              </p>

              <h6 className="mb-3 mt-5">Data Collection:</h6>
              <p>
                In our efforts to provide secure and improved services, we
                engage in selective data collection:
              </p>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item heading-color mb-0">
                  <b>User Information:</b> We may collect basic user information
                  to enhance your experience and provide personalized services.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Usage Data:</b>
                  Information about your interactions with the app, such as the
                  features you use and how often you use them.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Device Information:</b>
                  Data about your device, including its type, operating system,
                  and unique identifiers.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Analytics:</b> We may use analytics tools, such as Google
                  Analytics, to better understand user preferences and improve
                  our services.
                </li>
              </ul>

              <h6 className="mb-3 mt-5">Data Usage</h6>
              <p>Collected data serves various purposes, including:</p>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item heading-color mb-0">
                  <b>Service Enhancement:</b>
                  Analyzing user behavior helps us continually refine and
                  improve our services.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Personalization:</b>
                  Tailoring the app to offer a more personalized and convenient
                  user experience.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Security:</b> Protecting our services and users against
                  potential threats.
                </li>
              </ul>

              <h6 className="mb-3 mt-5">User Consent</h6>
              <p>
                Your consent is crucial in our data collection and usage
                process:
              </p>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item heading-color mb-0">
                  <b>Implicit Consent:</b> By using our app, you are granting us
                  permission to collect and process specified data. This forms
                  the foundation of our data collection policy, ensuring
                  transparency.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Explicit</b>
                  Information: Additional data collection, if required, will be
                  preceded by explicit information and an opportunity for you to
                  provide or withhold consent.
                </li>
              </ul>

              <h6 className="mb-3 mt-5">Data Storage and Security</h6>
              <p>We prioritize the security of your data:</p>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item heading-color mb-0">
                  <b>Storage Solutions:</b>
                  We employ industry-standard services to host and safeguard the
                  data we collect.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Security Measures:</b>
                  Proactive security protocols and infrastructure are in place
                  to prevent unauthorized access.
                </li>
              </ul>

              <h6 className="mb-3 mt-5">Cookies and Trackers</h6>
              <p>Usage of cookies and trackers:</p>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item heading-color mb-0">
                  <b>Cookie Usage:</b> We may use cookies to enhance user
                  experience and improve the efficiency of our services.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Control:</b> Users have the option to control cookie usage
                  through settings.
                </li>
              </ul>

              <h6 className="mb-3 mt-5">Third-party Integrations</h6>
              <p>
                We may collaborate with third-party providers for various
                functionalities, including analytics, communication tools, and
                affiliate partnerships. These entities have their own privacy
                policies and data handling practices.
              </p>

              <h6 className="mb-3 mt-5">Updates to the Privacy Policy</h6>
              <p>
                We continuously review and update our privacy policy to align
                with evolving standards. Users will be informed of significant
                modifications.
              </p>

              <h6 className="mb-3 mt-5">Logging Practices</h6>
              <p>Transparency in data retention:</p>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item heading-color mb-0">
                  <b>Purpose:</b> Our logging practices are tailored to the
                  fundamental objective of our services.
                </li>
                <li className="list-group-item heading-color mb-0">
                  <b>Retention:</b> We maintain logs for service optimization,
                  issue identification, and user satisfaction.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
