const IconArrowRight = () => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path
        d="m3.33,9h11.33m0,0l-4.25-4.25m4.25,4.25l-4.25,4.25"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconArrowRight;
